import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';

import { areEqualExtractStyle } from '@float/common/components/Schedule/util/diff';
import { useTaskMetasPrefetch } from '@float/common/lib/hooks/useTaskMetasPrefetch';
import { getLockPeriodDates } from '@float/common/selectors/lockLoggedTime';
import { RowMetaItem } from '@float/common/serena/Data/useRowMetas.helpers';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelectorStrict } from '@float/common/store';
import { ScheduleViewType } from '@float/constants/schedule';
import { LoggedTimeCell, PersonCell, ProjectCell } from '@float/types/cells';
import { ScheduleRow } from '@float/types/rows';

import buildItem from './buildItem';
import { getCellForProjectPlan } from './getCellForProjectPlan';
import { ProjectRowDates } from './ProjectRowDates/ProjectRowDates';
import { ScheduleActions } from './types';

import * as styles from './styles.css';

export type MainCellProps = {
  key?: string;
  rowIdx: number;
  colIdx: number;
  style: React.CSSProperties;
  row: ScheduleRow;
  rowMeta?: RowMetaItem;
  cell: PersonCell | ProjectCell | LoggedTimeCell;
  dayWidth: number;
  viewType: ScheduleViewType;
  actions: ScheduleActions;
  isColumnFetched: boolean;
  rowGroupTop: number;
  numDays: number;
  hourHeight: number;
  'data-floatid': string;
  linkedArrowTargetRef: React.RefObject<SVGSVGElement | null>;
};

// -- IMPORTANT NOTE [APA 2019-06-20] ------------------------------------------
// Be very careful with adding any hooks inside this function. A simple useState
// introduces a memory leak. I didn't track it down further than that.
function MainCell(props: MainCellProps) {
  const {
    rowIdx,
    colIdx,
    style,
    row,
    rowMeta,
    dayWidth,
    viewType,
    actions,
    isColumnFetched,
    rowGroupTop,
  } = props;

  const isProjectPlanView = viewType === 'projects';
  const rowGroupProjectId = 'projectId' in row ? row.projectId : undefined;

  const cell = useMemo(
    () =>
      isProjectPlanView
        ? getCellForProjectPlan(props.cell, rowGroupProjectId)
        : props.cell,
    [props.cell, isProjectPlanView, rowGroupProjectId],
  );

  const {
    dates,
    scrollWrapperRef,
    boundaryRef,
    numDays,
    printMode,
    suvSingleDay,
    singleUserView,
    logTimeView,
    logMyTimeView,
    cellsWrapper: { cells },
  } = useScheduleContext();

  const extraProps = {
    suvSingleDay,
    singleUserView,
    logTimeView,
    logMyTimeView,
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  const Inner = [];

  if (printMode) {
    // Printing backgrounds (whether image or repeat-linear-gradient) doesn't
    // work reliably. In print mode, we'll draw 1px wide vertical lines where
    // the grid normally is so that it renders accurately. We don't do this
    // in non-print mode because it adds a lot of DOM nodes and is slower.
    for (let i = 1; i < numDays; i++) {
      Inner.push(
        <div
          className={styles.printModeGrid}
          key={i}
          style={{
            left: i * dayWidth - 1,
          }}
        />,
      );
    }
  }

  const firstCellDay = dates.fromDescriptor(colIdx, 0);

  const loggedTimeLockPeriodDates = useAppSelectorStrict((state) =>
    getLockPeriodDates(state),
  );

  // Only render locked days in log time schedule view
  const lockedDayItems =
    logTimeView && rowMeta?.getLockedDayItems
      ? rowMeta.getLockedDayItems(firstCellDay, loggedTimeLockPeriodDates)
      : null;
  if (lockedDayItems) {
    lockedDayItems.forEach((i) => {
      const isEntityResizing =
        cell?.items?.some((ci) => {
          if ('entity' in ci && 'isResizingVertically' in ci.entity) {
            return ci.entity.isResizingVertically && ci.x === i.x;
          }

          return false;
        }) ?? false;

      Inner.push(
        // @ts-expect-error TODO(PI-245): The RowLockedDay type had fewer props than CellItem<'lockedDay'>
        buildItem(props, i, { ...extraProps, isEntityResizing }),
      );
    });
  }

  if (rowMeta?.getNonWorkDayItems && rowMeta.getNonWorkDayItems(firstCellDay)) {
    rowMeta.getNonWorkDayItems(firstCellDay).forEach((i) => {
      // @ts-expect-error TODO(PI-245): The RowNonWorkDay type had fewer props than CellItem<'nonWorkDay'>
      Inner.push(buildItem(props, i, extraProps));
    });
  }

  if (rowMeta?.start_date && dates.toNum(rowMeta.start_date) > colIdx * 7) {
    // The person's start date is on or after this column, add non-work days
    const [startColIdx, subCol] = dates.toDescriptor(rowMeta.start_date);
    for (let i = colIdx < startColIdx ? numDays - 1 : subCol - 1; i >= 0; i--) {
      if (
        rowMeta.getDailyWorkHours &&
        rowMeta.getDailyWorkHours(firstCellDay)[i] !== 0
      ) {
        Inner.push(
          buildItem(
            props,
            // @ts-expect-error TODO(PI-245): The NonWorkDay type had fewer props than CellItem<'nonWorkDay'>
            {
              type: 'nonWorkDay',
              key: `nonWorkDay:${i}`,
              x: i,
              w: 1,
            },
            extraProps,
          ),
        );
      }
    }
  }

  if (rowMeta?.end_date && dates.toNum(rowMeta.end_date) < (colIdx + 1) * 7) {
    // The person's end date is on or before this column, add non-work days
    const [endColIdx, subCol] = dates.toDescriptor(rowMeta.end_date);
    for (let i = colIdx > endColIdx ? 0 : subCol + 1; i < numDays; i++) {
      if (
        rowMeta.getDailyWorkHours &&
        rowMeta.getDailyWorkHours(firstCellDay)[i] !== 0
      ) {
        Inner.push(
          buildItem(
            props,
            // @ts-expect-error TODO(PI-245): The RowNonWorkDay type had fewer props than CellItem<'nonWorkDay'>
            {
              type: 'nonWorkDay',
              key: `nonWorkDay:${i}`,
              x: i,
              w: 1,
            },
            extraProps,
          ),
        );
      }
    }
  }

  const overtimeItems =
    cell &&
    'dayHours' in cell &&
    cell.dayHours &&
    rowMeta?.getOvertimeItems &&
    rowMeta.getOvertimeItems(firstCellDay, cell);

  if (overtimeItems) {
    for (let i = 0; i < overtimeItems.length; i++) {
      const isForced = cell.items.some((ci) => {
        if ('entity' in ci && 'isResizingVertically' in ci.entity) {
          const { x = 0 } = ci;

          return ci.entity.isResizingVertically && x <= i && x + ci.w > i;
        }

        return false;
      });

      // By definition, there is no overtime on a one-off day as the entire day
      // is overtime.
      const isPrevented = cell.items.some(
        ({ type, x = 0, w }) => type === 'oneOff' && x <= i && x + w > i,
      );

      const isOvertime =
        cell.dayHours[i] > rowMeta.getDailyWorkHours(firstCellDay)[i];

      if (!isPrevented && (isOvertime || isForced)) {
        Inner.push(
          // @ts-expect-error TODO(PI-245): The RowOvertime type had fewer props than CellItem<'overtime'>
          buildItem(props, overtimeItems[i], {
            suvSingleDay,
            logTimeView,
            logMyTimeView,
            singleUserView,
            isForced: isForced && !isOvertime,
          }),
        );
      }
    }
  }

  const isProjectRow = row.type === 'project';

  // Note: Checking for isProjectRow below for this:
  // https://app.asana.com/0/1199551221086134/1200011498269325/f
  if ((isColumnFetched || isProjectRow) && cell && cell.items) {
    cell.items.forEach((i, itemIdx) => {
      Inner.push(
        buildItem(props, i, {
          boundaryRef,
          wrapperRef,
          scrollWrapperRef,
          printMode,
          singleUserView,
          suvSingleDay,
          logTimeView,
          logMyTimeView,
          itemIdx,
          viewType,
          cells,
          dates,
        }),
      );
    });
  }

  // Project rows need custom background colors based on the project's start
  // and end dates. If the project fully spans a column, we can simply darken
  // the whole Positioner element. If it requires subcolumn calculations, we'll
  // use a custom background property with a gradient.
  let projectRowActiveDate = false;
  let customPositionerStyle;

  const isProjectRowWithDates =
    isProjectRow && row.data.start_date && row.data.end_date;

  if (isProjectRowWithDates && row.data.start_date && row.data.end_date) {
    const [minColIdx, minSubCol] = dates.toDescriptor(row.data.start_date);
    const [maxColIdx, maxSubCol] = dates.toDescriptor(row.data.end_date);

    if (colIdx > minColIdx && colIdx < maxColIdx) {
      projectRowActiveDate = true;
      customPositionerStyle = {
        background: row.darkBackground
          ? 'rgba(210, 210, 210, 0.3)'
          : 'transparent',
      };
    } else if (colIdx === minColIdx || colIdx === maxColIdx) {
      const start = `${colIdx === minColIdx ? minSubCol * dayWidth : 0}px`;
      const end =
        colIdx === maxColIdx ? `${(maxSubCol + 1) * dayWidth}px` : '100%';
      const background = row.darkBackground
        ? 'rgba(0,0,0,0.024)'
        : 'transparent';
      customPositionerStyle = {
        background: `repeating-linear-gradient(90deg,
          ${background} 0px,
          ${background} ${start},
          rgba(210, 210, 210, 0.3) ${start},
          rgba(210, 210, 210, 0.3) ${end},
          ${background} ${end},
          ${background} 100%
        )`,
      };
    }
  }

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!scrollWrapperRef.current) return;
      if (!wrapperRef.current) return;

      const { clientX, clientY } = e;
      const { offsetLeft } = wrapperRef.current;
      const offsetParent = wrapperRef.current
        .offsetParent as HTMLElement | null;
      const cellY =
        clientY -
        scrollWrapperRef.current.offsetTop -
        (rowGroupTop - scrollWrapperRef.current.scrollTop);

      const rawY = Number(offsetParent?.dataset.translateY) + cellY;
      const parentOffsetTop = offsetParent?.offsetTop ?? 0;

      actions.trackMouse(
        colIdx,
        rowIdx,
        clientX - offsetLeft + scrollWrapperRef.current.scrollLeft,
        clientY - parentOffsetTop,
        cellY,
        offsetLeft,
        rawY,
        clientX,
        clientY,
      );
    },
    [actions, colIdx, rowGroupTop, rowIdx, scrollWrapperRef],
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.button !== 0) return;

      const tagName = (e?.target as HTMLElement).tagName;

      if (tagName === 'BUTTON' || tagName === 'INPUT') {
        return;
      }
      actions.onCellMouseDown();
    },
    [actions],
  );

  const { prefetchTaskMetas } = useTaskMetasPrefetch();

  const handleMouseEnter = () => {
    if (row.type === 'project') {
      prefetchTaskMetas(row.data.project_id);
    }
  };

  return (
    <div
      ref={wrapperRef}
      style={style}
      className={classNames('MainCell-Wrapper', styles.wrapper)}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
    >
      <div
        className={styles.positioner}
        data-visible={!isColumnFetched}
        data-dark={row.darkBackground || projectRowActiveDate}
        style={customPositionerStyle}
      >
        {isProjectRowWithDates && row.data.end_date && row.data.start_date && (
          <ProjectRowDates
            colIdx={colIdx}
            dayWidth={dayWidth}
            endDate={row.data.end_date}
            startDate={row.data.start_date}
          />
        )}
        {Inner}
      </div>
    </div>
  );
}

export default React.memo(MainCell, areEqualExtractStyle);
