import { RowType } from '@float/constants/schedule';
import { Person, PersonProjectRow, Project, ProjectRow } from '@float/types';
import { Placeholder } from '@float/types/placeholder';

import { sortPeopleCustom, stringCompare } from '../../lib/sort';

export function getSingleProjectViewRows(
  singleProjectViewProject: Project & { all_people_ids?: number[] },
  people: Record<number, Person>,
  sortPrefs: {
    projview_people_order: Record<
      Project['project_id'],
      Record<number, number | null>
    >;
    projview_people_custom_priority: Record<
      Project['project_id'],
      Record<number, number[] | number | null>
    >;
  },
): Array<ProjectRow | PersonProjectRow> {
  const rows: Array<ProjectRow | PersonProjectRow> = [];

  if (!singleProjectViewProject) return rows;
  const prefix = RowType.Person;
  const projectId = singleProjectViewProject.project_id;

  const projectPeopleIds = singleProjectViewProject.all_people_ids ?? [];

  const projectPeople = projectPeopleIds
    .map((id) => people[id])
    .filter(Boolean);

  const projectRow: ProjectRow = {
    type: RowType.Project,
    id: `${RowType.Project}-${projectId}`,
    darkBackground: true,
    isCollapsed: false,
    data: singleProjectViewProject,
    numActivePeople: projectPeople.length,
    numTotalPeople: projectPeopleIds.length,
  };

  rows.push(projectRow);

  const {
    projview_people_order: people_order = {},
    projview_people_custom_priority: custom_priority = {},
  } = sortPrefs;

  projectPeople.sort((a, b) => {
    return stringCompare(a.name.toLowerCase(), b.name.toLowerCase());
  });

  const projectPeopleOrder = people_order[projectId];

  projectPeople.forEach((person, i) => {
    const order = projectPeopleOrder?.[person.people_id] ?? null;

    const projectPriority = person.projectPriority ?? {};
    const projectOrder = person.projectOrder ?? {};

    projectPriority[projectId] = order === null;
    projectOrder[projectId] = order === null ? i : Number(order);

    person.projectPriority = projectPriority;
    person.projectOrder = projectOrder;

    if (order !== null) {
      const customPriority = (
        custom_priority[projectId]?.[order as number] as number[]
      )?.indexOf(person.people_id);

      if (customPriority !== undefined && customPriority > -1) {
        const projectCustomPriority = person.projectCustomPriority ?? {};
        projectCustomPriority[projectId] = customPriority;
        person.projectCustomPriority = projectCustomPriority;
      }
    }
  });

  if (projectPeopleOrder) {
    projectPeople.sort(
      sortPeopleCustom([
        `projectOrder.${projectId}`,
        `projectPriority.${projectId}`,
        `projectCustomPriority.${projectId}`,
        'name',
      ]),
    );
  }

  for (const person of projectPeople) {
    rows.push({
      type: RowType.Person,
      id: `${prefix}-${person.people_id}`,
      key: `${prefix}-${projectId}-${person.people_id}`,
      projectId,
      peopleId: person.people_id,
      data: person,
      isLogTimeRow: false,
      darkBackground:
        person.people_type_id === 3 &&
        person.new_role_placeholder !== Placeholder.New,
    });
  }

  return rows;
}
