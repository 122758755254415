import React, { StrictMode, useMemo } from 'react';
import { t } from '@lingui/macro';
import styled, { css } from 'styled-components';

import {
  Insights,
  StatValue,
} from '@float/common/components/Schedule/insights/TimeRangeInsights.styles';
import { EarhartButton } from '@float/common/components/Schedule/util/Controls/styles';
import { Rights } from '@float/common/lib/acl';
import { getUser } from '@float/common/selectors/currentUser';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelectorStrict } from '@float/common/store';
import { ScheduleViewType } from '@float/constants/schedule';
import { DropdownSelect } from '@float/ui/deprecated/DropdownSelect/DropdownSelect';
import IconAddPerson from '@float/ui/deprecated/Icons/icon-add-person';
import IconAddProject from '@float/ui/deprecated/Icons/icon-add-project';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import { ScheduleActions } from '../MainCell/types';

export const Wrapper = styled.div`
  display: flex;
  padding: 0 8px;
  background: white;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.stroke1};
  box-sizing: border-box;
  height: 100%;
  align-items: center;
  color: ${(p) => p.theme.charcoalGrey};

  ${DropdownSelect._styles.StyledDropdownOption} {
    font-weight: normal;
  }

  ${DropdownSelect._styles.StyledDropdownSelect} {
    margin-left: 5px;
    margin-right: 5px;
    outline: none;
    &:last-child {
      margin-right: 0;
    }
  }

  ${DropdownSelect._styles.StyledDropdownLabel} svg {
    height: 24px;
    width: 24px;
    margin-left: 0;
  }

  @media screen and (max-width: 1010px) {
    ${DropdownSelect._styles.StyledDropdownSelect} {
      margin-left: 0;
    }

    ${StatValue} {
      overflow: visible;
    }
  }

  @media print {
    * {
      display: none;
    }
  }
`;

const SidebarOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;

  @media (max-width: 1010px) {
    &.desktop-only {
      display: none;
    }
  }
`;

const StickyCorner = styled.div<{ transparent?: boolean }>`
  position: absolute;
  left: 0;
  top: 5px;
  width: 260px;
  height: 48px;
  z-index: 1001;

  #schedule-container.single-user-schedule & {
    left: 0;
  }

  @media screen and (max-width: 1010px) {
    width: 64px;
  }

  ${(p) =>
    p.transparent &&
    css`
      height: 53px;
      top: -3px;
      pointer-events: none;

      ${Wrapper} {
        background: transparent;
        align-items: flex-start;
        border-bottom: 0;
      }

      ${Insights}, .time-range-picker-wrapper {
        pointer-events: all;
        top: 0;
        left: 0;
      }
    `}
`;

const CornerCellShadow = styled.div`
  position: absolute;
  overflow: hidden;
  width: 120%;
  height: 200%;
  bottom: 0px;
  left: 0px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 260px;
    height: 48px;
    border-radius: 12px 12px 0 0;
    box-shadow: 2px -1px 5px -1px rgba(0, 0, 0, 0.03);
  }

  pointer-events: none;

  @media (max-width: 1010px) {
    width: 100%;
    height: 100%;
  }
`;

const CornerCellAddButton = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <EarhartButton
      {...props}
      ref={ref}
      // @ts-expect-error EarhartButton doesn't have TS types
      isActivable={false}
      baseTextColor="emphasisMedium"
      hoverBgColor={'flayLight5'}
      mouseDownBgColor={'flayLight6'}
      style={{
        borderRadius: 6,
        padding: 2,
        height: 28,
        width: 28,
      }}
    >
      {props.children}
    </EarhartButton>
  );
});

function CornerCell({
  singleUserView,
  actions,
  cornerRef,
  insights,
  sort,
}: {
  singleUserView: boolean;
  cornerRef: React.MutableRefObject<HTMLDivElement>;
  actions?: ScheduleActions;
  insights?: React.ReactNode;
  sort?: React.ReactNode;
}) {
  if (singleUserView) {
    return (
      <StickyCorner transparent ref={cornerRef}>
        <Wrapper>{insights}</Wrapper>
      </StickyCorner>
    );
  }

  return (
    <StrictMode>
      <StickyCorner ref={cornerRef}>
        <CornerCellShadow />
        <Wrapper>
          {actions && <CornerCellAddBtn actions={actions} />}
          {sort}
          <Spacer size={4} />
          {insights}
        </Wrapper>
      </StickyCorner>
    </StrictMode>
  );
}

const CornerCellAddBtn = ({ actions }: { actions: ScheduleActions }) => {
  const { viewType } = useScheduleContext();

  // By returning boolean values we re-render only when the permissions change,
  // which is probably never in most user sessions.
  const canCreateProject = useAppSelectorStrict((state) =>
    Rights.canCreateProject(getUser(state)),
  );
  const canCreatePeople = useAppSelectorStrict((state) =>
    Rights.canCreatePeople(getUser(state)),
  );

  const addBtnConfig = useMemo(() => {
    if (viewType === ScheduleViewType.Projects) {
      if (canCreateProject) {
        return {
          hint: t`Add project`,
          icon: <IconAddProject />,
          onClick: (e: React.MouseEvent<HTMLDivElement>) =>
            actions.showAddProjectModal(e),
        };
      }
    } else {
      if (canCreatePeople) {
        return {
          hint: t`Add person`,
          icon: <IconAddPerson />,
          onClick: (e: React.MouseEvent<HTMLDivElement>) =>
            actions.showAddPersonModal(e),
        };
      }
    }

    return null;
  }, [canCreateProject, canCreatePeople, viewType]);

  if (!addBtnConfig) {
    return null;
  }

  return (
    <>
      <SidebarOption className="desktop-only" style={{ marginRight: 5 }}>
        <TextTooltip
          content={addBtnConfig.hint}
          placement="top"
          className="hint"
        >
          <CornerCellAddButton onClick={addBtnConfig.onClick}>
            {addBtnConfig.icon}
          </CornerCellAddButton>
        </TextTooltip>
      </SidebarOption>
    </>
  );
};

export default CornerCell;
