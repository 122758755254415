import React from 'react';
import { getDepartmentsByName } from 'selectors';

import { VirtualFilterTypes } from '@float/common/search/types';
import { useAppSelector } from '@float/common/store';
import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';

import { filterSubValue } from './styles.css';

const getSearchDisplayValueText = (val: string) => {
  if (val === '*') {
    return 'Any (All Types)';
  }

  return val;
};

const DepartmentFilterValue = ({ val }: { val: string }) => {
  const departmentsByName = useAppSelector(getDepartmentsByName);
  const department = departmentsByName[val];

  // if use is using an old link where the department doesn't exist anymore
  // or the department's name was changed, then we should only the name.
  if (department?.parent_id) {
    return (
      <>
        <IconArrowDownRight size={16} />
        <Spacer as="span" size={8} />
        {getSearchDisplayValueText(val)}
      </>
    );
  }

  return <>{getSearchDisplayValueText(val)}</>;
};

export const FilterValue = ({
  type,
  val,
}: {
  type: VirtualFilterTypes;
  val: string;
}) => {
  switch (type) {
    case 'department': {
      return <DepartmentFilterValue val={val} />;
    }
  }

  return <>{getSearchDisplayValueText(val)}</>;
};

export const FilterSubValue = ({ val }: { val: string }) => (
  <span className={filterSubValue}>{getSearchDisplayValueText(val)}</span>
);
