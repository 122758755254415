import { createSelector } from 'reselect';

import { Client } from '@float/types';

import { ReduxState } from '../../reducers/lib/types';

export const getClientsMap = (state: ReduxState): Record<number, Client> =>
  state.clients.clients;

export const getClients = createSelector([getClientsMap], (clientsMap) =>
  Object.values(clientsMap),
);

export const selectClientById = (
  state: ReduxState,
  clientId: number | undefined,
) => (clientId ? state.clients.clients[clientId] : undefined);
