import { useCallback } from 'react';

import { useAppStoreStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { queryApi } from './api/queryApi';
import {
  getSearchAutocompleteResults,
  SearchAutocompleteParams,
} from './selectors/getSearchAutocompleteResults';
import { searchService } from './service';

export function usePaginatedSearchAutocompleteCallback() {
  const store = useAppStoreStrict();

  return useCallback(
    async (params: SearchAutocompleteParams) => {
      if (!featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
        const state = store.getState();

        const filteredContext =
          await searchService.getSearchAutocompleteResults(state, params);

        return {
          filteredContext,
          // When the local search is enabled, we don't need to paginate
          hasNextPage: () => false,
          fetchNextPage: () => Promise.reject({}),
        };
      }

      const queryResult = await queryApi({
        query: params.rawInput,
        category: params.expandedCategory,
      });

      async function fetchNextPage() {
        return getSearchAutocompleteResults(store.getState(), {
          ...params,
          remoteQueryResult: {
            count: queryResult.count,
            items: await queryResult.fetchNextPage(),
          },
        });
      }

      return {
        filteredContext: getSearchAutocompleteResults(store.getState(), {
          ...params,
          remoteQueryResult: queryResult,
        }),
        hasNextPage: queryResult.hasNextPage,
        fetchNextPage,
      };
    },
    [store],
  );
}
