import { createSelector } from 'reselect';

import { ReduxState, ReduxStateStrict } from '@float/common/reducers/lib/types';
import { normalize } from '@float/common/search/helpers';
import { Person, Project, Tag } from '@float/types';

import { ProjectTagsSearchToken } from '../search/types';
import { getProjectsRawList } from './projects/getProjectsRawList';

export const isObjectTag = (
  tag: { name: string } | { label: string } | string,
): tag is { name: string } | { label: string } =>
  typeof tag === 'object' && tag !== null;

export const getTagLabel = (
  tag: { name: string } | { label: string } | string,
) => (isObjectTag(tag) ? ('name' in tag ? tag.name : tag.label) : tag);

export const DEFAULT_TAG_COLOR = '#E7E5EB';

export const getTags = (state: ReduxStateStrict) => state.tags.tags;

const getAdminTagList = <E extends Person | Project>(
  entities: E[],
  tags: Tag[],
) => {
  const entitiesByTagName: Record<string, E[]> = {};
  const tagByTagName: Record<string, { name: string; color: string }> = {};
  const defaultTagColor = DEFAULT_TAG_COLOR.slice(1); // remove '#'

  tags.forEach((tag) => {
    entitiesByTagName[tag.name] = [];
    tagByTagName[tag.name] = tag;
  });

  entities.forEach((entity) => {
    entity.tags?.forEach((tag) => {
      const tagName = getTagLabel(tag);

      if (!entitiesByTagName[tagName]) {
        entitiesByTagName[tagName] = [];
        tagByTagName[tagName] = {
          name: tagName,
          color: defaultTagColor,
        };
      }

      entitiesByTagName[tagName].push(entity);
    });
  });

  return Object.entries(entitiesByTagName).map(([name, entities]) => ({
    tag: tagByTagName[name],
    entities,
  }));
};

export const TAG_TYPE = {
  PROJECT: 1,
  PEOPLE: 2,
} as const;

export const isProjectTag = (tag: Tag) => tag.type === TAG_TYPE.PROJECT;

export const getTagsList = createSelector([getTags], (tags) =>
  Object.values(tags),
);

export const getProjectTags = createSelector([getTagsList], (tags) =>
  tags ? tags.filter(isProjectTag) : [],
);

export const getSortedTagsList = createSelector([getProjectTags], (tags) => {
  return tags
    .slice()
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
});

export const getProjectTagByLabel = createSelector([getProjectTags], (tags) => {
  return tags.reduce(
    (acc, tag) => {
      acc[tag.name] = tag;
      return acc;
    },
    {} as Record<string, Tag>,
  );
});

export const selectProjectsTagsSearchTokensMap = createSelector(
  [getProjectsRawList, getTagsList],
  (projects, tags) => {
    const map = new Map<string, ProjectTagsSearchToken>();

    for (const tag of tags) {
      if (tag.type === TAG_TYPE.PROJECT) {
        map.set(tag.name, {
          val: tag.name,
          normalizedVal: normalize(tag.name),
          type: '',
          key: '',
          projectIds: [],
        });
      }
    }

    for (const project of projects) {
      const id = project.project_id;
      const tags = Array.isArray(project.tags) ? project.tags : [];
      for (const tag of tags) {
        const entry = map.get(tag);

        if (entry === undefined) {
          map.set(tag, {
            val: tag,
            normalizedVal: normalize(tag),
            type: '',
            key: '',
            projectIds: [id],
          });
        } else {
          entry.projectIds.push(id);
        }
      }
    }

    return map;
  },
);

export const getProjectsTagsSearchTokens = createSelector(
  [selectProjectsTagsSearchTokensMap],
  (map) => {
    return Array.from(map.values());
  },
);

export const getProjectsTagsValues = createSelector(
  [getProjectsRawList, getTagsList],
  (projects, tags) => {
    const set = new Set<string>();

    for (const tag of tags) {
      if (tag.type === TAG_TYPE.PROJECT) {
        set.add(tag.name);
      }
    }

    for (const project of projects) {
      for (const tag of project.tags) {
        set.add(tag);
      }
    }

    return Array.from(set);
  },
);

export const getPeopleTagsValues = createSelector(
  [(state: ReduxStateStrict) => state.people.people, getTagsList],
  (people: Record<number, Person>, tags) => {
    const set = new Set<string>();

    for (const tag of tags) {
      if (tag.type === TAG_TYPE.PEOPLE) {
        set.add(tag.name);
      }
    }

    for (const person of Object.values(people)) {
      for (const tag of person.tags) {
        set.add(tag.name);
      }
    }

    return Array.from(set);
  },
);

export const getAdminProjectTags = createSelector(
  [(state: ReduxState) => state.projects.projects, getProjectTags],
  (projects: Record<number, Project>, projectTags) => {
    return getAdminTagList(Object.values(projects), projectTags || []);
  },
);

export const isPeopleTag = (tag: Tag) => tag.type === TAG_TYPE.PEOPLE;

const getPeopleTags = createSelector([getTagsList], (tags) =>
  tags ? tags.filter(isPeopleTag) : [],
);

export const getPeopleTagByLabel = createSelector([getPeopleTags], (tags) => {
  return tags.reduce(
    (acc, tag) => {
      acc[tag.name] = tag;
      return acc;
    },
    {} as Record<string, Tag>,
  );
});

export const getTagsLoaded = createSelector(
  [(state: ReduxState) => state.tags.loadState],
  (loadState) => loadState === 'LOADED',
);

export const getAdminPeopleTags = createSelector(
  [(state: ReduxState) => state.people.people, getPeopleTags],
  (people: Record<number, Person>, peopleTags) => {
    return getAdminTagList(Object.values(people), peopleTags || []);
  },
);
