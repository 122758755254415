import React, { memo } from 'react';

import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import { trimTextForReadibility } from '@float/libs/utils/string/trimTextForReadibility';
import { FIN } from '@float/theme';
import { Tooltip } from '@float/ui/components/Tooltip';

import { truncateFields } from './helpers';

import * as styles from './styles.css';

export const DotSeparator = ({
  fill = FIN.Color.Radix.Flay.Flay10,
  className,
}: {
  fill?: string;
  className?: string;
}) => (
  <svg
    width="3"
    height="4"
    viewBox="0 0 3 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="1.5" cy="2" r="1.5" fill={fill} />
  </svg>
);

export const DensitySpacer = memo(
  ({ density }: { density: 'default' | 'compact' }) => (
    <div
      className={styles.densitySpacer[density]}
      data-testid="density-spacer"
    />
  ),
);

const MaybeTooltip = ({
  content,
  ogContent,
  showTooltip,
}: {
  content: ReturnType<typeof trimTextForReadibility>;
  ogContent: string;
  showTooltip: boolean;
}) => {
  // const showTooltip = content !== ogContent;

  return showTooltip ? (
    <Tooltip content={ogContent} placement="bottom">
      <span>{content.value}</span>
    </Tooltip>
  ) : (
    <span>{content.value}</span>
  );
};

const SingleFieldDisplay = ({ content }: { content: string }) => (
  <div className={styles.field({ display: 'single' })}>{content}</div>
);

const DualFieldDisplay = ({
  clientName,
  projectCode,
  ogProjectCode,
  ogClientName,
}: {
  clientName: ReturnType<typeof trimTextForReadibility>;
  projectCode: ReturnType<typeof trimTextForReadibility>;
  ogClientName: string;
  ogProjectCode: string;
}) => {
  return (
    <div className={styles.field({ display: 'dual' })}>
      <MaybeTooltip
        content={projectCode}
        ogContent={ogProjectCode}
        showTooltip={projectCode.trimmed}
      />
      <DotSeparator
        fill={FIN.Color.Radix.Flay.Flay10}
        className={styles.separator}
      />
      <MaybeTooltip
        content={clientName}
        ogContent={ogClientName}
        showTooltip={clientName.trimmed}
      />
    </div>
  );
};

export type ProjectCodeAndClientProps = {
  clientId?: number;
  clientName?: string;
  projectCode?: string;
  density: 'default' | 'compact';
};

export const ProjectCodeAndClient = ({
  clientId,
  clientName = '',
  projectCode,
  density,
}: ProjectCodeAndClientProps) => {
  const { isProjectCodesEnabled } = useProjectCodesPreference();

  if (!isProjectCodesEnabled) {
    if (!clientId) return null;

    return (
      <>
        <DensitySpacer density={density} />
        <SingleFieldDisplay content={clientName} />
      </>
    );
  }

  if (projectCode && clientId) {
    const truncated = truncateFields(projectCode, clientName);

    return (
      <>
        <DensitySpacer density={density} />
        <DualFieldDisplay
          clientName={truncated.clientName}
          ogClientName={clientName}
          projectCode={truncated.projectCode}
          ogProjectCode={projectCode}
        />
      </>
    );
  }

  if (projectCode || clientId) {
    const content = projectCode ? projectCode : clientName;
    return (
      <>
        <DensitySpacer density={density} />
        <SingleFieldDisplay content={content} />
      </>
    );
  }

  return null;
};
