// Initialize error tracking and translations before anything else
import './lib/config/initializeWebConfig';
import './lib/logger/initializeWebLogger';
import './lib/storage/initializeWebStorage';
import './lib/tracking/initializeGoogleTagManager';
import './lib/tracking/initializeAmplitude';
import '@float/libs/web/media/initializeWebMedia';
import './translations';
import '@float/common/lib/requestIdleCallback';
import './perfMonitoring/rum';
import './floatPolyfills';
import 'formdata-polyfill';
import 'core-js/stable/set/intersection';
import 'core-js/stable/set/union';
import 'core-js/stable/set/difference';

import React from 'react';
import gsap from 'gsap';
import { Flip } from 'gsap/Flip';
import { createRoot } from 'react-dom/client';

import { searchService } from '@float/common/search/service';
import { spawnSearchWorker } from '@float/common/search/service/spawnSearchWorker';
import { todayManager } from '@float/libs/dates';

import { FullPageLoader } from './components/FullPageLoader';
import {
  initFeatureFlags,
  initStoreAndThirdPartyServices,
} from './main.helpers';
import { RootRouter } from './router';

async function main() {
  const wrapper = document.querySelector('.app.content-wrapper') as Element;

  // We want to keep today updated if the user keeps the app open for more than a day
  todayManager.startTodayAutoUpdate();
  gsap.registerPlugin(Flip);

  await initFeatureFlags();

  // `searchWorker` must be initialized before the redux store to ensure correct
  // middleware is installed
  const worker = await spawnSearchWorker();
  searchService.setWorkerApi(worker);

  const root = createRoot(wrapper);

  root.render(<FullPageLoader isLoading />);

  const { store, pageData } = await initStoreAndThirdPartyServices();

  root.render(<RootRouter store={store} data={pageData} />);
}

main();
