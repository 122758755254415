// Remember to use the "Remove Feature Flag" template when adding a new feature flag task in Linear
export enum FeatureFlag {
  CreateLayeredProject = 'create-layered-project',
  DraftStatus = 'draft-status',
  LinearIntegration = 'linear-integration',
  OnboardingExampleData = 'onboarding-example-data',
  OnboardingWelcomeVideo = 'onboarding-welcome-video',
  PhaseSidePanel = 'phase-side-panel',
  PlaceholdersPackaging = 'placeholders-packaging',
  ProjectBudgetByTasks = 'project-budget-by-tasks',
  RolesRates = 'role-rates',
  ScheduleDataWindowing = 'schedule-data-windowing',
  SearchBeyondLimits = 'search-beyond-limits',
  SearchBeyondLimitsMinQueryLength = 'search-beyond-limits-min-query-length',
  SearchBeyondLimitsNewAlg = 'search-beyond-limits-new-alg',
  SearchBeyondLimitsDisableRevalidation = 'search-beyond-limits-disable-revalidation',
  SidePanelBesidesAllocationModal = 'side-panel-besides-allocation-modal',
  TagGroups = 'tag-groups',
  TargetedDebugLogs = 'rollbar-debug',
  AllocationByTotalHours = 'allocation-by-total-hours',
  WebSocketMonitoring = 'websocket-monitoring',
  ProjectPlanInMainNav = 'project-plan-in-main-nav',
  AllocationByAvailabilityPercentage = 'allocation-by-availability-percentage',
  EditTaskModalAssigneesFieldTotalHoursUpdate = 'edit-task-modal-assignees-field-total-hours-update',
  LightOnboarding = 'activation-squad-light-onboarding',
  PasswordStrengthMeter = 'activation-squad-password-strength-meter',
  ProjectCodes = 'project-codes',
  AllocationByTimeframe = 'allocation-by-timeframe',
  ActivationSquadHidePhasesInProjectSidePanel = 'activation-squad-hide-phases-in-project-side-panel',
  SingleProjectView = 'single-project-view',
  AllowNotFilteringForAllProperties = 'allow-not-filtering-for-all-properties', // Jan 7, 2025 – Haakon Jack – https://linear.app/float-com/issue/PI-438/remove-allow-not-filtering-for-all-properties
  AggregateTagAndStatusFilteringForProjects = 'aAggregate-tag-and-status-filtering-for-projects', // Jan 13, 2025 – Haakon Jack - feature/pi-442-remove-aaggregate-tag-and-status-filtering-for-projects
  // Note: "'aAggregate-tag-and-status-filtering-for-projects" is not a typo, launch darkly generated it that way :/
}

export const ALL_FEATURE_FLAGS = Object.values(FeatureFlag);

export const FEATURE_FLAG_VARIANTS = [
  'on',
  'gentle-refresh',
  'strong-refresh',
  'off',
] as const;

// Add here the feature flags that aren't actively being used and are meant to
// be deprecated soon
export const FEATURE_FLAGS_TO_DEPRECATE = [
  FeatureFlag.OnboardingExampleData,
  FeatureFlag.OnboardingWelcomeVideo,
  FeatureFlag.LightOnboarding,
  FeatureFlag.ActivationSquadHidePhasesInProjectSidePanel,
];

// Add here the permanent feature flags
export const FEATURE_FLAGS_PERMANENT = [FeatureFlag.TargetedDebugLogs];

// See "Debug Code in Production" in the `float-javascript` github wiki
// https://github.com/floatschedule/float-javascript/wiki/Customer-Issues#2-debug-code-in-production
export enum TargetedDebugLogFlagVariants {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  PerformanceMonitoring = 'performanceMonitoring',
  // Extend as needed with variations names
}

export const LAUNCH_DARKLY_CLIENT_ID = {
  production: '634d5f3676538411510c5c28',
  staging: '64ef1f0a1398ce1235c3a3b7',
  develop: '634d5f3676538411510c5c27',
} as const;
