import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t } from '@lingui/macro';
import cx from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { find } from 'lodash';
import qs from 'query-string';
import styled, { css } from 'styled-components';

import { signOut } from '@float/common/lib/url';
import { isTimeTrackingEnabled } from '@float/common/selectors/companyPrefs';
import { getUser } from '@float/common/selectors/currentUser';
import { getActiveTimer } from '@float/common/selectors/timer';
import { getViewAndFiltersQueryString } from '@float/common/selectors/views';
import { useAppSelector } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { useIsSmallMediaSizeActive } from '@float/libs/hooks/media';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { FIN } from '@float/theme';
import EH from '@float/ui/deprecated/Earhart';
import {
  IconFolder,
  IconHelpCircle,
  IconLogout,
  IconNotification,
  IconSettings,
  IconStopwatch,
} from '@float/ui/deprecated/Earhart/Icons';
import { Hr } from '@float/ui/deprecated/Hr/Hr';
import IconClose from '@float/ui/deprecated/Icons/icon-close';
import IconNavLogTime from '@float/ui/deprecated/Icons/icon-nav-log-time';
import { Col, Flex, Row, Spacer } from '@float/ui/deprecated/Layout/Layout';
import colors from '@float/ui/deprecated/Theme/colors';
import { TooltipWithHintAndMenu } from '@float/ui/deprecated/Tooltip/TooltipWithHintAndMenu';
import { IconProjectPlan } from '@float/ui/icons/essentials/IconProjectPlan';
import { IconReport } from '@float/ui/icons/essentials/IconReport';
import { IconSchedule } from '@float/ui/icons/essentials/IconSchedule';
import { IconUsers } from '@float/ui/icons/essentials/IconUsers';
import {
  ProjectPlanViewSource,
  trackProjectPlanView,
} from '@float/web/lib/tracking/trackProjectPlanView';
import { ProjectPlanInNavCallout } from '@float/web/OnboardingManager/components/callouts/ProjectPlanInNav';
import { getOnboardingCalloutsStatus } from '@float/web/store/onboardingManager/selectors/callouts';

import { NotificationsPanel } from '../../NotificationsPanel/';
import {
  SidebarButtonGroup,
  SidebarNavControlGroup,
  SideNavCol,
  SideNavMenuBackground,
  StyledAvatarImage,
  StyledAvatarMenuIcon,
  StyledPlaceHolderIcon,
} from '../styles';
import { GlobalTimer } from './GlobalTimer';
import HelpMenu from './HelpMenu';
import PersonalMenu from './PersonalMenu';
import TeamSettingsMenu from './TeamSettingsMenu';

const hrStyle = {
  backgroundColor: '#A6B3C7',
  width: 35,
  margin: 0,
};

const isNavigationActive = (to) => {
  const parsedTo = qs.parseUrl(to);
  const isActive = parsedTo.url === location.pathname;
  if (isActive) {
    return true;
  }

  return false;
};

const MenuIcon = ({
  icon,
  menu,
  isActive,
  hint,
  disableHoverStyles,
  onHide,
  hideOnClick = true,
  onOpenAutoFocus,
}) => {
  return (
    <TooltipWithHintAndMenu
      key="viewmode"
      arrow={false}
      hint={hint}
      menu={() => menu}
      onHide={onHide}
      zIndex={10007}
      menuProps={{
        distance: 5,
        placement: 'right-end',
        hideOnClick,
        onOpenAutoFocus,
      }}
      hintProps={{
        placement: 'right',
        distance: 5,
      }}
    >
      {(isOpen, showMenu) => (
        <NavRailItem
          icon={icon}
          disableHoverStyles={disableHoverStyles}
          isActive={isActive || isOpen}
          onClick={showMenu}
        />
      )}
    </TooltipWithHintAndMenu>
  );
};

const NavRailItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

const NavRailItemNameWrapper = styled.span`
  ${EH.Typography.Label10.M500}
  margin-top: 5px;
`;

const navRailCSS = css`
  cursor: pointer;
  flex: 0;
  color: ${EH.Colors.Core.Text.Secondary};
  --svg-icon-color: ${EH.Colors.Core.Icon.Secondary};

  &.active {
    color: ${EH.Colors.Core.Text.Default};
  }

  ${(props) =>
    !props.disableHoverStyles &&
    css`
      &:hover {
        color: ${EH.Colors.Core.Text.Secondary};
        ${NavRailItemIconWrapper} {
          background-color: ${EH.Colors.Core.ActionFlay.Hovered};
        }
      }

      &:active {
        color: ${EH.Colors.Core.Text.Secondary};
        ${NavRailItemIconWrapper} {
          background-color: ${EH.Colors.Core.ActionFlay.Pressed};
        }
      }

      &.active {
        color: ${EH.Colors.Core.Text.Default};
        ${NavRailItemIconWrapper} {
          background-color: ${FIN.Color.Action.ActionFlay.Pressed};
        }
      }
    `}
`;
const NavRailItemWrapper = styled(Col)`
  ${navRailCSS};
`;

const MobileNavRailItemWrapper = styled(Row)`
  ${navRailCSS};

  ${NavRailItemNameWrapper} {
    ${EH.Typography.Label14.M500}
    margin: 0px;
    margin-left: 12px;
  }

  .active {
  }
`;

const MobileSignoutRow = styled(Row)`
  position: sticky;
  bottom: 0px;
  box-sizing: border-box;
  padding: 13px 24px;
  align-items: center;
  flex: 0;
  font-size: 16px;
  color: ${EH.Colors.Core.Text.Default};
  border-top: 1px solid ${EH.Colors.Core.Stroke.Tertiary};
  background: ${EH.Colors.FIN.Lt.Surface.Surf1};
  cursor: pointer;
`;

const NavRailItem = React.forwardRef((props, ref) => {
  return (
    <NavRailItemWrapper
      ref={ref}
      {...props}
      className={cx(props.className, {
        active: props.isActive,
      })}
    >
      <NavRailItemIconWrapper data-callout-id={name.toLowerCase()}>
        {props.icon}
      </NavRailItemIconWrapper>
      {props.name && (
        <NavRailItemNameWrapper>{props.name}</NavRailItemNameWrapper>
      )}
    </NavRailItemWrapper>
  );
});

const NavigationIcon = ({ name, icon, to, onClick }) => {
  const isActive = isNavigationActive(to);

  return (
    <Link to={to} onClick={onClick}>
      <NavRailItem isActive={isActive} icon={icon} name={name} />
    </Link>
  );
};

const MobileNavigationRow = ({ name, icon, to, onClick }) => {
  const isActive = isNavigationActive(to);

  return (
    <Link to={to} onClick={onClick}>
      <MobileNavRailItemWrapper
        className={cx({
          active: isActive,
        })}
      >
        <NavRailItemIconWrapper data-callout-id={name.toLowerCase()}>
          {icon}
        </NavRailItemIconWrapper>
        <NavRailItemNameWrapper>{name}</NavRailItemNameWrapper>
      </MobileNavRailItemWrapper>
    </Link>
  );
};

const getUserAvatar = (people, accounts, user) => {
  const personData =
    people[user.people_id] ||
    find(people, (p) => p.account_id == user.account_id) ||
    accounts[user.account_id] ||
    user;

  return personData?.avatar_file || personData?.avatar || null;
};

function trackProjectPlanViewFromNav() {
  trackProjectPlanView(ProjectPlanViewSource.MainNav);
}

export const SideNav = ({
  people,
  accounts,
  user,
  notificationsSeen,
  queryFromFilters,

  // mobile props
  isMobileMenuOpen,
  hideMobileSideNav,
  mobileControls,
}) => {
  const isSmallBreakpointActive = useIsSmallMediaSizeActive();
  const avatarUrl = getUserAvatar(people, accounts, user);
  const canManage = user.account_tid != 4;
  const timeTrackingEnabled = useAppSelector(isTimeTrackingEnabled);
  const hasPeopleId = Boolean(Number(user.people_id));
  const showTimeTracking = timeTrackingEnabled > 0 && hasPeopleId;
  const callouts = useAppSelector(getOnboardingCalloutsStatus);
  const activeTimer = useAppSelector(getActiveTimer);
  const isProjectPlanNavEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.ProjectPlanInMainNav,
  );

  const getUrlWithFilter = (pathname) => {
    return `${pathname}${queryFromFilters ? `?${queryFromFilters}` : ''}`;
  };

  if (isSmallBreakpointActive) {
    return (
      <>
        {isMobileMenuOpen && (
          <SideNavMenuBackground onClick={hideMobileSideNav} />
        )}
        <SideNavCol className={cx('mobile', { open: isMobileMenuOpen })}>
          <Flex
            onClick={hideMobileSideNav}
            style={{
              position: 'absolute',
              // As per specs: close icon has to be distanced by 25px from top-right edge of sidemenu.
              // I added 10px padding to have a bigger tap area on mobile.
              // then I adjusted top and right to make sure positioning stays the same.
              top: '15px',
              right: '15px',
              padding: '10px',
            }}
          >
            <IconClose color={colors.blueGrey} />
          </Flex>
          <SidebarButtonGroup
            alignItems="flex-start"
            flex="0"
            onClick={hideMobileSideNav}
          >
            <MobileNavigationRow
              name="Schedule"
              icon={<IconSchedule />}
              to={getUrlWithFilter('/')}
            />
            {canManage && (
              <>
                <Spacer size={16} />
                <MobileNavigationRow
                  name="People"
                  icon={<IconUsers />}
                  to={getUrlWithFilter('/people')}
                />
              </>
            )}
            {canManage && (
              <>
                <Spacer size={16} />
                <MobileNavigationRow
                  name="Projects"
                  icon={<IconFolder size={24} />}
                  to={getUrlWithFilter('/projects')}
                />
              </>
            )}
            {/*
            Note: Reports page isn't ready to be displayed on mobile.
            <Spacer size={16} />
            <MobileNavigationRow
              name="Report"
              icon={<IconReport size={24} />}
              to={getUrlWithFilter('/report')}
            />
            */}
            {showTimeTracking && (
              <>
                <Spacer size={16} />
                <MobileNavigationRow
                  name="Log time"
                  icon={<IconNavLogTime />}
                  to={getUrlWithFilter('/log-time')}
                />
              </>
            )}
          </SidebarButtonGroup>
          {mobileControls && (
            <>
              <SidebarNavControlGroup flex="0">
                {mobileControls}
              </SidebarNavControlGroup>
              <Spacer size={25} />
            </>
          )}

          <SidebarButtonGroup
            flex="1"
            style={{
              overflow: 'scroll',
              position: 'relative',
              borderTop: `1px solid ${EH.Colors.Core.Stroke.Tertiary}`,
              paddingTop: '0px !important',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100%',
              }}
            >
              <TeamSettingsMenu user={user} onClick={hideMobileSideNav} />
              <PersonalMenu user={user} onClick={hideMobileSideNav} />
              <HelpMenu user={user} onClick={hideMobileSideNav} />
            </div>
          </SidebarButtonGroup>
          <MobileSignoutRow
            onClick={() => {
              signOut();
            }}
          >
            <IconLogout size={24} />
            <Spacer size={12} />
            Sign out
          </MobileSignoutRow>
        </SideNavCol>
      </>
    );
  }

  return (
    <SideNavCol
      className="global-side-nav"
      role="navigation"
      aria-label={t`Global navigation`}
    >
      <SidebarButtonGroup>
        <div data-product-tour-id="navigation-desktop">
          <Col gap={16}>
            <NavigationIcon
              name="Schedule"
              icon={<IconSchedule />}
              to={getUrlWithFilter('/')}
            />
            {isProjectPlanNavEnabled && (
              <div data-callout-id="project-plan-nav-callout">
                <NavigationIcon
                  name="Project plan"
                  icon={<IconProjectPlan />}
                  to={getUrlWithFilter('/project-plan')}
                  onClick={trackProjectPlanViewFromNav}
                />
              </div>
            )}
            {isProjectPlanNavEnabled && canManage && <Hr style={hrStyle} />}
            {canManage && (
              <>
                <div data-callout-id="manage">
                  <NavigationIcon
                    name="People"
                    icon={<IconUsers />}
                    to={getUrlWithFilter('/people')}
                  />
                </div>
                <NavigationIcon
                  name="Projects"
                  icon={<IconFolder size={24} />}
                  to={getUrlWithFilter('/projects')}
                />
              </>
            )}
            <NavigationIcon
              name="Report"
              icon={<IconReport />}
              to={getUrlWithFilter('/report')}
            />
            {showTimeTracking && (
              <>
                <Hr style={hrStyle} />
                <NavigationIcon
                  name="Log time"
                  icon={<IconStopwatch size={24} />}
                  to={getUrlWithFilter('/log-time')}
                />
                <AnimatePresence>
                  {!!activeTimer && (
                    <>
                      <Spacer size={10} />
                      <GlobalTimer timer={activeTimer} />
                    </>
                  )}
                </AnimatePresence>
              </>
            )}
          </Col>
        </div>
      </SidebarButtonGroup>
      <SidebarButtonGroup justifyContent="flex-end">
        <div data-callout-id="team">
          <MenuIcon
            hint="Team"
            icon={<IconSettings size={32} />}
            menu={<TeamSettingsMenu user={user} />}
            isActive={location.pathname.startsWith('/admin')}
          />
        </div>
        <Spacer size={20} />
        <div data-callout-id="help-guides">
          <MenuIcon
            hint={callouts.showHelpGuides ? null : 'Help'}
            icon={<IconHelpCircle size={32} />}
            menuType="menu-E400"
            menu={<HelpMenu user={user} />}
          />
        </div>
        <Spacer size={20} />
        <div data-callout-id="notifications">
          <MenuIcon
            hint="Notifications"
            icon={
              <IconNotification size={32} showIndicator={!notificationsSeen} />
            }
            menu={<NotificationsPanel />}
            hideOnClick={false}
            onOpenAutoFocus={prevent}
          />
        </div>
        <Spacer size={20} />
        <StyledAvatarMenuIcon>
          <MenuIcon
            hint="Personal"
            disableHoverStyles
            menu={<PersonalMenu user={user} />}
            icon={
              <>
                <StyledPlaceHolderIcon />
                <StyledAvatarImage
                  imageUrl={avatarUrl}
                  name={user.name}
                  size="sm2"
                />
              </>
            }
          />
        </StyledAvatarMenuIcon>
      </SidebarButtonGroup>
      {isProjectPlanNavEnabled && <ProjectPlanInNavCallout />}
    </SideNavCol>
  );
};

const mapStateToProps = (state) => ({
  notificationsSeen: !state.notifications?.unread,
  accounts: state.accounts.accounts,
  people: state.people.people,
  user: getUser(state),
  queryFromFilters: getViewAndFiltersQueryString(state),
});

export const MainSideNav = connect(mapStateToProps)(SideNav);

export default MainSideNav;
