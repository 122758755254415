export enum ScheduleViewType {
  People = 'people',
  Projects = 'projects',
  SingleProject = 'singleProject',
}

export enum RowType {
  Project = 'project',
  Person = 'person',
}
