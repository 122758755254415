import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { selectUserAccessRights } from '@float/common/selectors/userAccessRights';
import { PROMPTS } from '@float/constants/prompts';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { PromptId } from '@float/types/prompts';
import {
  OnboardingSurveyConfig,
  OnboardingSurveyForExampleData,
} from '@float/web/store/onboardingManager/constants';
import {
  OnboardingSurveyData,
  OnboardingSurveyDataEntry,
  OnboardingSurveyStatus,
} from '@float/web/store/onboardingManager/types';

import { getNavigateToPrompt, getPromptsUserHasDone } from './common';

export const getOnboardingSurveyConfig = (
  state?: ReduxStateStrict,
): Partial<OnboardingSurveyData> => {
  if (featureFlags.isFeatureEnabled(FeatureFlag.OnboardingExampleData)) {
    return OnboardingSurveyForExampleData;
  }

  return OnboardingSurveyConfig;
};

export const getAllSurveySteps = createSelector(
  [getOnboardingSurveyConfig],
  (onboardingSurveyConfig) => {
    return Object.values(onboardingSurveyConfig).reduce(
      (
        acc: OnboardingSurveyDataEntry['prompts'],
        current: OnboardingSurveyDataEntry,
      ) => acc.concat(current.prompts),
      [],
    );
  },
);

export const getAllSurveyPrompts = createSelector(
  [getAllSurveySteps],
  (allSurveyOnboardingSteps) => {
    const prompts: PromptId[] = allSurveyOnboardingSteps.filter(
      (prompt) => prompt !== PROMPTS.onboardingSurveySuccess,
    );

    return prompts;
  },
);

export const getSurveyStatus = createSelector(
  [
    getOnboardingSurveyConfig,
    getAllSurveySteps,
    getPromptsUserHasDone,
    selectUserAccessRights,
    getNavigateToPrompt,
  ],
  (
    onboardingSurgeyConfig,
    allSurveySteps,
    promptsUserHasDone,
    userAccessRights,
    navigateToPrompt,
  ) => {
    const pages = Object.keys(onboardingSurgeyConfig) as Array<
      keyof typeof OnboardingSurveyConfig
    >;

    const status: OnboardingSurveyStatus = {
      navigation: {
        page: -1,
        totalPages: pages.length,
        subpage: -1,
        totalSubpages: -1,
        prompt: undefined,
        previousPage: -1,
      },
      completed: false,
    };

    const { navigation } = { ...status };

    pages.every((pageKey, pageIndex) => {
      const page = onboardingSurgeyConfig[pageKey]!;

      page.prompts.every((prompt, promptIndex, all) => {
        if (
          !navigateToPrompt &&
          !promptsUserHasDone.includes(prompt as PromptId) &&
          navigation.page < 0
        ) {
          navigation.page = pageIndex;
          navigation.subpage = promptIndex;
          navigation.totalSubpages = all.length;
          navigation.prompt = prompt;
          return false;
        }

        if (navigateToPrompt === prompt) {
          navigation.page = pageIndex;
          navigation.subpage = promptIndex;
          navigation.totalSubpages = all.length;
          navigation.prompt = prompt;
          return false;
        }

        return true;
      });

      // break out of the loop if a page was set already
      return navigation.page < 0;
    });

    status.navigation = navigation;

    // show only to account owners that have not completed it yet
    const { isAccountOwner } = userAccessRights.accessRights;

    status.completed =
      !isAccountOwner() ||
      // the user has gone through all survey prompts (including the success page)
      allSurveySteps.every((prompt) =>
        promptsUserHasDone.includes(prompt as PromptId),
      );

    return status;
  },
);
