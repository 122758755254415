import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { updateUserPrefLocally } from '@float/common/actions';
import { setViewTypeAction } from '@float/common/actions/appInfo';
import { useAppDispatchDecorator } from '@float/common/store';
import { ScheduleViewType } from '@float/constants/schedule';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import {
  ProjectPlanViewSource,
  trackProjectPlanView,
} from '@float/web/lib/tracking/trackProjectPlanView';

export const useApplyProjectPlanFilter = () => {
  const setViewType = useAppDispatchDecorator(setViewTypeAction);
  const updateViewPref = useAppDispatchDecorator(updateUserPrefLocally);
  const history = useHistory();
  const isProjectPlanInNav = featureFlags.isFeatureEnabled(
    FeatureFlag.ProjectPlanInMainNav,
  );

  const applyProjectPlanFilter = useCallback(
    (projectName: string, ref: ProjectPlanViewSource) => {
      const queryString = `?project=${encodeURIComponent(projectName)}`;
      if (isProjectPlanInNav) {
        trackProjectPlanView(ref);
        history.push(`/project-plan${queryString}`);
        return;
      }

      setViewType(ScheduleViewType.Projects);
      updateViewPref('sked_view_type', 'projects');
      history.push(`/${queryString}`);
    },
    [history, isProjectPlanInNav, setViewType, updateViewPref],
  );

  return applyProjectPlanFilter;
};
