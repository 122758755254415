import { memoize } from 'proxy-memoize';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { ScheduleViewType } from '@float/constants/schedule';

import { getLocationPathname } from '../location';

export const selectIsLogTimeViewType = (state: ReduxStateStrict) => {
  return (
    Boolean(state?.appInfo?.logTimeView) &&
    state?.appInfo?.viewType !== ScheduleViewType.Projects
  );
};

export const selectScheduleViewType = (state: ReduxStateStrict) => {
  return state?.appInfo?.viewType || ScheduleViewType.People;
};

export const getIsProjectPlanView = memoize(
  (state: ReduxStateStrict): boolean => {
    return selectScheduleViewType(state) === ScheduleViewType.Projects;
  },
);

export const getIsLogTimeView = memoize((state: ReduxStateStrict): boolean => {
  const location = getLocationPathname(state);
  const logTimeView = selectIsLogTimeViewType(state);

  if (location === '/log-time') return true;

  return logTimeView && location === '/';
});
