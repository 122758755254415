import React from 'react';

import { useSearchFiltersAnalyticsTracking } from '@float/common/lib/hooks/useSearchFiltersAnalyticsTracking';
import { config } from '@float/libs/config';

import { FullPageLoader } from '../FullPageLoader';
import { Portal as InnerPortal } from './Portal';
import { useNoSession } from './useNoSession';
import { usePortalDataLoad } from './usePortalDataLoad';

function isWebScheduleView(pathname: string) {
  return (
    pathname === '/' ||
    pathname === '/log-time' ||
    pathname.startsWith('/share/')
  );
}

export function Portal() {
  const isSharedLink = config.isSharedView;
  const isLoading = usePortalDataLoad(isSharedLink);

  useNoSession({ isSharedLink, loading: isLoading });

  useSearchFiltersAnalyticsTracking('filter-timeline', {
    enabled: isWebScheduleView(location.pathname) && !isLoading,
  });

  return (
    <FullPageLoader isLoading={isLoading}>
      <InnerPortal />
    </FullPageLoader>
  );
}
