import { useIsSingleProjectPlanView } from '@float/common/serena/util/getIsSingleProjectPlanView';
import { ScheduleViewType } from '@float/constants/schedule';

export const useCTAState = (
  viewType: ScheduleViewType,
  showProjectsToggle: boolean,
) => {
  const isSingleProjectView = viewType === ScheduleViewType.SingleProject;
  const isProjectsView = viewType === ScheduleViewType.Projects;
  const { isSingleProjectPlanView, project: singleProjectPlanProject } =
    useIsSingleProjectPlanView();
  const hasAssignTeamMemberDropdown =
    isSingleProjectPlanView && singleProjectPlanProject?.canEdit;

  const hasCollapseToggle =
    isProjectsView &&
    !hasAssignTeamMemberDropdown &&
    showProjectsToggle &&
    !isSingleProjectView;
  return {
    hasCollapseToggle,
    hasAssignTeamMemberDropdown,
  };
};
