import React from 'react';
import { Trans } from '@lingui/macro';

import { Project } from '@float/types/project';
import { Button } from '@float/ui/components/Button';

import { SingleProjectMenu } from './SingleProjectMenu';
import { SingleProjectStatus } from './SingleProjectStatus';

import * as styles from './styles.css';

export const SingleProjectActions = ({ project }: { project: Project }) => {
  return (
    <div className={styles.projectActionsContainer}>
      <SingleProjectStatus
        projectId={project.project_id}
        projectStatus={project.status}
      />
      <SingleProjectMenu project={project} />
      <Button size="xsmall" className={styles.estimateButton}>
        <Trans>Save estimate</Trans>
      </Button>
    </div>
  );
};
