import { ScheduleViewType } from '@float/constants/schedule';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

export type GetViewTypeResult =
  (typeof ScheduleViewType)[keyof typeof ScheduleViewType];

export type GetViewTypeParams = {
  logTimeView: boolean;
  path: string;
  viewType?: GetViewTypeResult;
  viewTypePref: GetViewTypeResult;
};

export function getViewType(params: GetViewTypeParams): GetViewTypeResult {
  const { logTimeView, path, viewType, viewTypePref } = params;

  if (logTimeView) {
    return ScheduleViewType.People;
  }

  let result = viewType ?? ScheduleViewType.People;

  if (viewTypePref && viewTypePref !== viewType) {
    result = viewTypePref;
  }

  const isSingleProjectView =
    featureFlags.isFeatureEnabled(FeatureFlag.SingleProjectView) &&
    path?.startsWith('/project/');

  if (isSingleProjectView) {
    return ScheduleViewType.SingleProject;
  }

  if (featureFlags.isFeatureEnabled(FeatureFlag.ProjectPlanInMainNav)) {
    if (path === '/project-plan') {
      return ScheduleViewType.Projects;
    }

    // If "Project plan" is lifted to main nav, then always render the
    // "People" view on "Schedule" page; since "Project plan" view will
    // have its own dedicated page at the `/project-plan` path.
    if (result === ScheduleViewType.Projects) {
      return ScheduleViewType.People;
    }
  }

  return result;
}
