import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { VirtualItem } from '@tanstack/react-virtual';
import cn from 'classnames';

import { TYPE_TO_CATEGORY } from '@float/common/search/helpers';
import { Candidate } from '@float/common/search/selectors/getSearchAutocompleteResults/types';
import { FilterCategory } from '@float/common/search/types';
import { logger } from '@float/libs/logger';

import { ResultsListProps } from '../SearchFilterResults/components/ResultsList';
import { Item } from './components/Item';
import { ItemCategory } from './components/ItemCategory';
import { ItemDepartment } from './components/ItemDepartment';

import * as styles from './styles.css';

type SearchFilterItemProps = ResultsListProps & {
  virtualItem: VirtualItem;
};

export const SearchFilterItem = (props: SearchFilterItemProps) => {
  const {
    addFilter,
    addValue,
    categoryIndices,
    data,
    deleteSavedSearch,
    disableMultiSelect,
    expandCategory,
    expandedCategory,
    highlightedIndex,
    measureElement,
    removeValue,
    replaceValue,
    selectedValues,
    setHighlightedIndex,
    virtualItem,
  } = props;

  const { index } = virtualItem;

  const item = data[index];

  if (!item) return null;

  const category = TYPE_TO_CATEGORY[item.type] as FilterCategory;
  const isFirstInCategory =
    !expandedCategory && categoryIndices[category] === index;
  const highlighted = highlightedIndex === index;
  // remove onClick from 'Contains' header
  const onClick = category !== 'contains' ? expandCategory : undefined;

  const isSelectable =
    expandedCategory &&
    expandedCategory !== 'savedSearches' &&
    !disableMultiSelect;

  const isSelected = selectedValues.includes(item.val);
  const isGlobSelected = item.val !== '*' && selectedValues.includes('*');

  const toggleCheckBox = () => {
    if (isSelected) {
      removeValue(item);
    } else {
      addValue(item);
    }
  };

  const onDelete = (item: Candidate) => {
    deleteSavedSearch(item);
  };

  return (
    <li
      ref={measureElement}
      className={styles.item}
      data-index={index}
      onMouseEnter={() => setHighlightedIndex(index)}
    >
      <div
        className={cn(styles.itemSpacer, {
          [styles.separator]: item.showSeparatorAfter,
        })}
      >
        {isFirstInCategory && (
          <ItemCategory type={category} onClick={onClick} />
        )}

        {/* Workaround for https://linear.app/float-com/issue/CS-961/when-filtering-a-specific-project-number-it-gives-weve-got-a-problem */}
        <ErrorBoundary
          FallbackComponent={() => null}
          onError={logger.getReactErrorBoundaryHandler('SearchFilterItem', {
            searchItem: true,
          })}
        >
          {item.type === 'department' && (
            <ItemDepartment
              checked={isSelected || isGlobSelected}
              className={cn({ [styles.ident]: !expandedCategory })}
              highlighted={highlighted}
              isSelectable={isSelectable}
              isSelected={isSelected}
              item={item}
              onDelete={onDelete}
              onDeselect={() => removeValue(item)}
              onReplace={replaceValue}
              onSelect={() => addFilter(item)}
              selectedValues={selectedValues}
            />
          )}

          {item.type !== 'department' && (
            <Item
              checked={isSelected || isGlobSelected}
              className={cn({
                [styles.ident]: !expandedCategory,
                [styles.projectCode]: Boolean(item.subVal),
              })}
              highlighted={highlighted}
              isCheckboxDisabled={isGlobSelected}
              isCheckboxReadOnly={isGlobSelected}
              isDeletable={category === 'savedSearches'}
              isSelectable={isSelectable}
              item={item}
              onClickCheckBox={toggleCheckBox}
              onDelete={onDelete}
              onSelect={() => addFilter(item)}
            />
          )}
        </ErrorBoundary>
      </div>
    </li>
  );
};
