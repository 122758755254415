import { map, uniq } from 'lodash';

import { SearchReducerContext } from '@float/common/search/types';
import { getLoggedTimesListRaw } from '@float/common/selectors/loggedTimes';
import { getPeopleListRaw } from '@float/common/selectors/people/people';
import { getPhases } from '@float/common/selectors/phases';
import {
  getProjectsRawList,
  selectIsProjectCodesEnabled,
} from '@float/common/selectors/projects';
import {
  getPeopleTasks,
  getProjectsTasksIds,
  getProjectsWithTasks,
} from '@float/common/selectors/search';
import { getTasksList } from '@float/common/selectors/tasks/getTasksList';
import {
  Person,
  Phase,
  Project,
  SearchTimeoff,
  Timeoff,
  TimeoffType,
} from '@float/types';

import {
  CATEGORY_TO_TYPE,
  FILTERABLE_KEYS,
  normalize as normalizeVal,
  savedSearchesToFilters,
} from '../../search/helpers';
import {
  getAccounts,
  getAccurateNameByAccountId,
  getManagedPeopleIdByAccountId,
} from '../../selectors/people';
import {
  getPeopleTagsValues,
  getProjectsTagsSearchTokens,
} from '../../selectors/tags';
import { getTimeoffsListRaw } from '../../selectors/timeoffs';
import { ReduxStateStrict } from '../lib/types';

const sortByNormalizedVal = (
  a: { normalizedVal: string },
  b: { normalizedVal: string },
) =>
  a.normalizedVal > b.normalizedVal
    ? 1
    : a.normalizedVal === b.normalizedVal
      ? 0
      : -1;

const PROJECT_STATUSES = [
  { value: 'Mine', showSeparatorAfter: true },
  { value: 'Billable' },
  { value: 'Non-billable', showSeparatorAfter: true },
  { value: 'Active' },
  { value: 'Archived', showSeparatorAfter: true },
  { value: 'Draft' }, // status = 0
  { value: 'Tentative' }, // status = 1
  { value: 'Confirmed' }, // status = 2
];

export const projectStatuses = PROJECT_STATUSES.map(
  ({ value, showSeparatorAfter }) => ({
    val: value,
    normalizedVal: normalizeVal(value),
    key: '',
    type: 'projectStatus' as const,
    ...(showSeparatorAfter ? { showSeparatorAfter } : {}),
  }),
);

const TASK_STATUSES = [
  { value: 'Billable' },
  { value: 'Non-Billable', showSeparatorAfter: true },
  { value: 'Draft' }, // status = 0
  { value: 'Tentative' }, // status = 1
  { value: 'Confirmed' }, // status = 2
  { value: 'Completed' }, // status = 3
];

const taskStatuses = TASK_STATUSES.map(({ value, showSeparatorAfter }) => ({
  val: value,
  normalizedVal: normalizeVal(value),
  key: '',
  type: '',
  ...(showSeparatorAfter ? { showSeparatorAfter } : {}),
}));

const PERSON_TYPES = [
  'Employee',
  'Contractor',
  'Placeholder',
  'Full-time',
  'Part-time',
];

const personTypes = PERSON_TYPES.map((personType) => ({
  val: personType,
  normalizedVal: normalizeVal(personType),
  key: '',
  type: '',
})).sort(sortByNormalizedVal);

type BaseSearchToken = {
  normalizedVal: string;
  val: string;
  key: string;
  type: string;
};

type ToSearchItemListParams<E, T extends BaseSearchToken> = {
  input: E[];
  skip?: (entity: E) => boolean;
  getKey: (entity: E) => string;
  onCreate: (key: string, entity: E) => T;
  onUpdate?: (token: T, entity: E) => void;
  uniqProps?: (keyof T)[];
  map?: Map<string, T>;
};

/**
 * Transforms a list of entites
 * to a list of search tokens
 *
 * Written to be as fast as possible
 *
 */
const toSearchItemList = <T extends BaseSearchToken, E>({
  input,
  skip,
  getKey,
  onCreate,
  onUpdate,
  uniqProps,
  map = new Map(),
}: ToSearchItemListParams<E, T>) => {
  const result = [];

  for (const entity of input) {
    if (skip?.(entity)) continue;

    const key = getKey(entity);

    let token = map.get(key);

    if (token !== undefined) {
      if (onUpdate !== undefined) {
        onUpdate(token, entity);
      }
    } else {
      token = onCreate(key, entity);
      map.set(key, token);
      result.push(token);
    }
  }

  if (uniqProps) {
    for (const token of result) {
      for (const prop of uniqProps) {
        // @ts-expect-error given the generic nature of token, token[prop] is unknown
        token[prop] = [...new Set(token[prop])];
      }
    }
  }

  result.sort(sortByNormalizedVal);
  return result;
};

export const getDerivedContext = (action: {
  fullState: ReduxStateStrict;
}): SearchReducerContext => {
  const fs = action.fullState;

  // !!! People ------------------------------------------------------------

  const peopleList: Person[] = getPeopleListRaw(fs);

  const people = toSearchItemList({
    input: peopleList,
    skip: (p) => p.name === null,
    getKey: (p) => normalizeVal(p.name),
    onCreate: (key, p) => ({
      val: p.name,
      normalizedVal: key,
      ids: [p.people_id],
      isActive: Boolean(p.active),
      key: '',
      type: '',
    }),
    onUpdate: (token, p) => {
      token.ids.push(p.people_id);
      if (p.active) token.isActive = true;
    },
  });

  // !!! Job Titles --------------------------------------------------------

  const jobTitles = toSearchItemList({
    input: peopleList,
    skip: (p) => p.job_title === null || p.job_title === '',
    getKey: (p) => normalizeVal(p.job_title),
    onCreate: (key, p) => ({
      val: p.job_title!, // the skip functions ensures that job_title here is non-empty
      normalizedVal: key,
      key: '',
      type: '',
    }),
  });

  // !!! Person Tags -------------------------------------------------------
  const personTags = getPeopleTagsValues(fs).map((name) => {
    const key = normalizeVal(name);
    return {
      val: name,
      normalizedVal: key,
      key: '',
      type: '',
    };
  });
  personTags.sort(sortByNormalizedVal);

  // !!! Managers -------------------------------------------------------

  // TODO Can we optimize this?
  const accounts = getAccounts(fs);
  const accountManagedPeople = getManagedPeopleIdByAccountId(fs);
  const accurateNameByAccountId = getAccurateNameByAccountId(fs);
  const managers = Object.keys(accountManagedPeople).map((managerAccoundId) => {
    const id = Number(managerAccoundId);
    const accountName = accurateNameByAccountId[id];
    const key = normalizeVal(accountName);
    return {
      ...accounts[id],
      id: managerAccoundId,
      val: accountName,
      normalizedVal: key,
      key: '',
      type: '',
    };
  });

  // const managers = sortBy(, 'normalizedVal')

  // !!! Departments -------------------------------------------------------

  const allDepatments = fs.departments.departments;
  const departments: Array<BaseSearchToken & { id: number | null }> = map(
    allDepatments,
    (department) => ({
      ...department,
      id: department.id,
      val: department.name,
      normalizedVal: normalizeVal(department.name),
      key: '',
      type: 'department',
    }),
  );

  // !!! Timeoffs ----------------------------------------------------------

  const timeoffTypes: Record<number, TimeoffType> =
    fs.timeoffTypes.timeoffTypes;
  const activeTimeoffTypes = new Set();

  for (const timeoffType of Object.values(timeoffTypes)) {
    if (timeoffType.active)
      activeTimeoffTypes.add(normalizeVal(timeoffType.timeoff_type_name));
  }

  const resolveTimeoffTypeName = (t: Timeoff | SearchTimeoff) => {
    if (t.timeoff_type_name) return t.timeoff_type_name;

    if ('timeoff_type_id' in t)
      return timeoffTypes[t.timeoff_type_id]?.timeoff_type_name || '';

    return '';
  };

  const timeoffs = toSearchItemList<
    BaseSearchToken & { isActive: boolean; allPeopleIds: number[] },
    Timeoff | SearchTimeoff
  >({
    input: getTimeoffsListRaw(fs),
    skip: (t) => 'region_holiday_id' in t && Boolean(t.region_holiday_id),
    getKey: (t) => normalizeVal(resolveTimeoffTypeName(t)),
    onCreate: (key, t) => ({
      val: resolveTimeoffTypeName(t),
      normalizedVal: key,
      key: '',
      type: '',
      isActive: activeTimeoffTypes.has(key),
      allPeopleIds: [...t.people_ids],
    }),
    uniqProps: ['allPeopleIds'],
    onUpdate: (token, t) => {
      token.allPeopleIds.push(...t.people_ids);
    },
  });

  // !!! Projects ----------------------------------------------------------

  const projectsList = getProjectsRawList(fs);
  const projectsMap = new Map();
  const isProjectCodesEnabled = selectIsProjectCodesEnabled(fs);
  const getProjectsKey = (p: Project) =>
    normalizeVal(
      `${p.project_name}${
        isProjectCodesEnabled && p.project_code ? `|${p.project_code}` : ''
      }`,
    );

  const projects = toSearchItemList({
    input: projectsList,
    skip: (p) => !p.project_name,
    getKey: getProjectsKey,
    onCreate: (key, p) => ({
      val: p.project_name,
      subVal: isProjectCodesEnabled ? p.project_code : null,
      normalizedVal: key,
      ids: [p.project_id],
      clientIds: [p.client_id],
      allPeopleIds: [...p.people_ids],
      isActive: Boolean(p.active),
      key: '',
      type: '',
    }),
    uniqProps: ['allPeopleIds'],
    onUpdate: (token, p) => {
      token.ids.push(p.project_id);

      if (!token.clientIds.includes(p.client_id)) {
        token.clientIds.push(p.client_id);
      }

      token.allPeopleIds.push(...p.people_ids);

      // If there are many projects with the same name
      // we consider the search token active if one of them is active
      // this way the token will be placed at the bottom of the
      // search filters list only if all the corresponding projects are archived
      if (p.active) token.isActive = true;
    },
    map: projectsMap,
  });

  // !!! Phases ------------------------------------------------------------

  const projectPhaseIds: Record<number, Set<number>> = {};
  const projectAllPeople: Record<number, Set<number>> = {};

  projectsList.forEach((project) => {
    projectAllPeople[project.project_id] = new Set(project.people_ids || []);
  });

  const phasesList: Phase[] = getPhases(fs);

  const phases = toSearchItemList<
    BaseSearchToken & { allPeopleIds: number[]; ids: number[] },
    Phase
  >({
    input: phasesList,
    skip: (p) => !p.phase_name,
    getKey: (p) => normalizeVal(p.phase_name),
    onCreate: (key, p) => ({
      val: p.phase_name,
      normalizedVal: key,
      ids: [p.phase_id],
      allPeopleIds: [...p.people_ids],
      key: '',
      type: '',
    }),
    uniqProps: ['allPeopleIds'],
    onUpdate: (token, p) => {
      token.ids.push(p.phase_id);
      token.allPeopleIds.push(...p.people_ids);
    },
  });

  for (const p of phasesList) {
    const phasePeople = (p.people_ids || []).filter((x) => x);

    if (!projectPhaseIds[p.project_id]) {
      projectPhaseIds[p.project_id] = new Set([p.phase_id]);
    } else {
      projectPhaseIds[p.project_id].add(p.phase_id);
    }

    const project = fs.projects.projects[p.project_id];
    if (project) {
      const projectItem = projectsMap.get(getProjectsKey(project));
      projectItem.allPeopleIds = uniq([
        ...projectItem.allPeopleIds,
        ...p.people_ids,
      ]);

      if (!projectAllPeople[p.project_id]) {
        projectAllPeople[p.project_id] = new Set(phasePeople);
      } else {
        const set = projectAllPeople[p.project_id];
        phasePeople.forEach((personId) => set.add(personId));
      }
    }
  }

  // !!! Project Owners ----------------------------------------------------

  const projectOwners = toSearchItemList<
    BaseSearchToken & { allPeopleIds: number[]; accountIds: number[] },
    Project
  >({
    input: projectsList,
    skip: (p) => !p.project_manager || !fs.accounts.accounts[p.project_manager],
    getKey: (p) => normalizeVal(fs.accounts.accounts[p.project_manager].name),
    onCreate: (key, p) => ({
      val: fs.accounts.accounts[p.project_manager].name,
      normalizedVal: key,
      accountIds: [p.project_manager],
      allPeopleIds: [...projectAllPeople[p.project_id]],
      key: '',
      type: '',
    }),
    uniqProps: ['allPeopleIds'],
    onUpdate: (token, p) => {
      if (!token.accountIds.includes(p.project_manager)) {
        token.accountIds.push(p.project_manager);
      }

      token.allPeopleIds.push(...projectAllPeople[p.project_id]);
    },
  });

  // !!! Clients -----------------------------------------------------------

  // Note that we have to loop over the projects in the state, not the
  // grouped "projects" variable. This is because the grouped variable
  // aggregates all people on all projects with the same name, regardless
  // of client.
  const peopleIdsByClient: Record<number, Set<number>> = {};
  const peopleWithProjects = new Set<number>();
  projectsList.forEach((project) => {
    if (!project.client_id) return;

    if (!peopleIdsByClient[project.client_id]) {
      peopleIdsByClient[project.client_id] = new Set();
    }

    if (projectAllPeople[project.project_id]) {
      const values = peopleIdsByClient[project.client_id];

      for (const personId of projectAllPeople[project.project_id]) {
        peopleWithProjects.add(personId);
        values.add(personId);
      }
    }
  });

  const clients = map(fs.clients.clients, (c) => ({
    id: c.client_id,
    val: c.client_name,
    normalizedVal: normalizeVal(c.client_name),
    peopleIds: [...(peopleIdsByClient[c.client_id] || [])],
    key: '',
    type: '',
  }));
  clients.sort(sortByNormalizedVal);

  // !!! Project Tags ------------------------------------------------------

  const projectTags = getProjectsTagsSearchTokens(fs);
  projectTags.sort(sortByNormalizedVal);

  // !!! Tasks -------------------------------------------------------------

  const peopleTaskStatuses: Record<number, Set<string>> = {};

  const tasksList = getTasksList(fs);
  const taskNames = tasksList.map((t) => t.name);

  // Note: Using a for-of because taskNames.push(...array) could trigger a RangeError: Maximum call stack size exceeded
  // see https://linear.app/float-com/issue/CS-1346/saved-filter-disappears-after-saving-team-cannot-access-any-saved
  for (const loggedTime of getLoggedTimesListRaw(fs)) {
    if ('task_name' in loggedTime) {
      taskNames.push(loggedTime.task_name);
    }
  }

  const tasks = toSearchItemList({
    input: taskNames,
    skip: (t) => !t,
    getKey: (t) => normalizeVal(t),
    onCreate: (key, t) => ({
      val: t,
      normalizedVal: key,
      key: '',
      type: '',
    }),
  });

  tasksList.forEach((t) => {
    const statuses: string[] = [];
    statuses.push(t.billable ? 'billable' : 'non-billable');
    if (t.status === 1) statuses.push('tentative');
    else if (t.status === 2) statuses.push('confirmed');
    else if (t.status === 3) statuses.push('completed');

    t.people_ids.forEach((personId) => {
      if (!peopleTaskStatuses[personId]) {
        peopleTaskStatuses[personId] = new Set(statuses);
      } else {
        statuses.forEach((status) => {
          peopleTaskStatuses[personId].add(status);
        });
      }
    });
  });

  // !!! Timeoff Statuses --------------------------------------------------
  const timeoffStatusesList = [
    fs.companyPrefs.timeoff_approvals ? 'Approved' : 'Confirmed', // status = 2
    'Tentative', // status = 1
  ];
  if (fs.companyPrefs.timeoff_approvals) {
    timeoffStatusesList.unshift('Declined');
  }

  const timeoffStatuses = timeoffStatusesList
    .map((timeoffStatus) => ({
      val: timeoffStatus,
      normalizedVal: normalizeVal(timeoffStatus),
      key: '',
      type: '',
    }))
    .sort(sortByNormalizedVal);

  // !!! Saved Searches ----------------------------------------------------

  const savedSearches = savedSearchesToFilters(fs.currentUser.savedSearches);

  // !!! Cleanup -----------------------------------------------------------

  departments.push({
    val: 'None',
    normalizedVal: 'none',
    id: null,
    key: '',
    type: '',
  });

  jobTitles.push({
    val: 'None',
    normalizedVal: 'none',
    key: '',
    type: '',
  });

  projects.push({
    val: 'None',
    normalizedVal: 'none',
    ids: [],
    allPeopleIds: [],
    key: '',
    type: '',
  });

  tasks.push({
    val: 'No task used',
    normalizedVal: 'no task used',
    key: '',
    type: '',
  });

  const context = {
    // used for the filters list, autocomplete and to generate reports queries
    clients,
    departments,
    jobTitles,
    managers,
    people,
    personTags,
    personTypes,
    phases,
    projectOwners,
    projects,
    projectStatuses,
    projectTags,
    savedSearches,
    tasks,
    taskStatuses,
    timeoffs,
    timeoffStatuses,

    // used only for search
    peopleTasks: getPeopleTasks(fs),
    projectTaskIds: getProjectsTasksIds(fs),
    projectPhaseIds,
    projectAllPeople,
    peopleTaskStatuses,
    peopleWithProjects,
    accountManagedPeople,
    accurateNameByAccountId,
    projectsWithTasks: getProjectsWithTasks(fs),
  };

  FILTERABLE_KEYS.forEach((key) => {
    context[key].forEach((obj) => {
      obj.type = CATEGORY_TO_TYPE[key];
      obj.key = `${CATEGORY_TO_TYPE[key]}-${obj.val}`;
    });
  });

  return context;
};
