import React, { createElement, ReactNode } from 'react';

import {
  MediaContextProvider,
  MediaContextType,
} from '@float/libs/contexts/MediaContext';

import {
  ColorThemeContextProvider,
  ColorThemeContextType,
} from './ColorThemeContext';
import { PrintContextProvider, PrintContextType } from './PrintContext';

type ContextShapes = {
  printContext: PrintContextType;
  themeContext: ColorThemeContextType;
  mediaContext: MediaContextType;
};

type Overrides = {
  [Key in keyof ContextShapes]?: Partial<ContextShapes[Key]>;
};

type ProviderConfig<K extends keyof ContextShapes = keyof ContextShapes> = [
  K,
  React.FC<{
    children?: ReactNode;
    overrides?: Overrides[K];
  }>,
];

// Note that the providers at the bottom will be highest in the tree
const ALL_PROVIDERS = [
  ['printContext', PrintContextProvider],
  ['colorThemeContext', ColorThemeContextProvider],
  ['mediaContext', MediaContextProvider],
];

export const UIContextProviders: React.FC<{
  children: ReactNode;
  overrides?: Overrides;
}> = ({ children, overrides = {} }) => {
  let allContextsApplied = children;

  for (const [
    contextName,
    contextProvider,
  ] of ALL_PROVIDERS as ProviderConfig[]) {
    const contextOverrides = overrides[contextName];

    allContextsApplied = createElement(
      contextProvider,
      { overrides: contextOverrides },
      allContextsApplied,
    );
  }

  return allContextsApplied;
};
